import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { Select as GuiSelect } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { withErrorBoundary } from '../error-boundary/error-boundary';

const RawSelect = ({
  name,
  options,
  control,
  errors,
  width = null,
  groupOrder = null,
  onChange = null,
  placeholder = null,
  disabled = false,
  clearable = false,
  multi = false,
  testId = null,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { onChange: fieldOnChange, ...props } = field;
        delete props.ref;

        return (
          <GuiSelect
            {...props}
            options={options}
            width={width}
            groupOrder={groupOrder}
            disabled={disabled}
            clearable={clearable}
            multi={multi}
            testId={testId}
            placeholder={placeholder}
            onChange={(e) => {
              fieldOnChange(e.target.value);
              if (onChange) {
                onChange(e);
              }
            }}
            error={
              translations[errors[name]]
                ? t(errors[name])
                : errors[name] || null
            }
          />
        );
      }}
    />
  );
};

export const Select = withErrorBoundary(RawSelect);
