import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { apiCallBegan } from '~store/middleware/api/api';
import { reorderOperation } from '~store/utils-store';
import {
  depthInequality,
  drillingUnit,
  wellConfiguration,
  valueTypes,
  kpiFractionTypes,
  linkFields,
} from '~src/enums/settings';
import i18n from 'i18next';
import translations from '~src/internationalisation/translation-map.json';
import { estimateTypes, distributionTypes } from '~src/enums/tasks';
import { CostType, QuantityType } from '~src/enums/cost-setup';
import { toast } from '@oliasoft-open-source/react-ui-library';
import {
  findConnectedCategories,
  nextName,
  updateActivityIds,
  updateCostSetupIds,
} from './user-settings.utils';

export const initialUnitsSettings = {
  length: 'm',
  time: 'h',
};

export const initialTemplate = {
  templateId: '',
  highLevel: {
    wellConfiguration: wellConfiguration.OFFSHORE,
    drillingUnit: drillingUnit.SEMI_SUBMERSIBLE,
    waterDepthInequality: depthInequality.LESS_OR_EQUAL,
    wellheadDepthInequality: depthInequality.LESS_OR_EQUAL,
  },
  activityModel: {},
  costSetup: {
    costItem: [],
    costCategory: [],
  },
};

export const initialOperation = {
  id: '',
  name: '',
  from: 0,
  depthFromLink: linkFields.CUSTOM,
  depthToLink: linkFields.CUSTOM,
  to: 0,
  comments: '',
  activities: [],
  operationCode: null,
  subOperationCode: null,
  sectionsOperationId: null,
};

export const initialActivity = {
  parentId: 0,
  name: '',
  certainty: 100,
  estimateType: estimateTypes.OPERATION_TIME,
  from: 0,
  to: 0,
  min: 0,
  most: 1,
  max: 2,
  activityCode: null,
  comments: '',
  distribution: distributionTypes.PERT,
  withBranch: false,
  isBranch: false,
  isApplyFromOffset: false,
};

export const createInitialBranch = (parentId) => ({
  parentId,
  id: uuidv4(),
  name: 'New Branch',
  certainty: 100,
  estimateType: estimateTypes.OPERATION_TIME,
  from: 0,
  to: 0,
  min: 0,
  most: 0,
  max: 0,
  activityCode: null,
  comments: '',
  distribution: distributionTypes.PERT,
  withBranch: false,
  isBranch: true,
});

export const initialCostItem = {
  cost: 0,
  name: '',
  type: CostType.DayRate,
  currency: 'USD',
  costItemId: null,
  parentId: '',
  operations: [],
  quantity: QuantityType.Length,
  length: 0,
  volume: 0,
  unitCost: 0,
};

export const initialKpi = {
  name: '',
  valueType: valueTypes.CALCULATED,
  numerator: kpiFractionTypes.COST,
  numeratorUnit: 'USD',
  denominator: kpiFractionTypes.TIME,
  denominatorUnit: 'h',
  isTarget: true,
  targetInequality: depthInequality.LESS_OR_EQUAL,
  target: 0,
};

const userSettings = createSlice({
  name: 'userSettings',
  initialState: {
    isAdding: false,
    isFetching: false,
    showTemplatesForm: false,
    showOperationsForm: false,
    showActivityForm: false,
    showKpiForm: false,
    showDeleteOperationsModal: false,
    showDeleteActivitiesModal: false,
    showDeleteCostItemModal: false,
    showImportOperationModal: false,
    showDeleteCostCategoryModal: false,
    showDeleteKpiModal: false,
    showAddOperationFromSectionsDataModal: false,
    showAddActivityFromSectionsDataModal: false,
    selectedId: null,
    sectionTypes: [],
    codes: [],
    settings: {
      units: { ...initialUnitsSettings },
      templates: [initialTemplate],
      kpi: [],
    },
    currencies: [],
  },
  reducers: {
    userSettingsRequested: (userSettings) => {
      userSettings.isFetching = true;
      userSettings.settings = {};
    },
    userSettingsReceived: (userSettings, action) => {
      userSettings.isFetching = false;
      userSettings.settings = action.payload;
    },
    userSettingsSaveRequested: (userSettings) => {
      userSettings.isAdding = true;
    },
    userSettingsSaveFailed: (userSettings) => {
      userSettings.isAdding = false;
      toast({
        message: {
          type: 'Error',
          content: 'Error adding template',
        },
      });
    },
    userSettingsSaved: (userSettings, action) => {
      userSettings.isAdding = false;
      userSettings.settings = action.payload;
      userSettings.showTemplatesForm = false;
    },
    templateSelected: (userSettings, action) => {
      userSettings.settings.templates = userSettings.settings.templates.map(
        (item, i) => {
          item.active = i === action.payload;
          return item;
        },
      );
      userSettings.showTemplatesForm = true;
    },
    templatesSaved: (userSettings, action) => {
      userSettings.isAdding = false;
      userSettings.showTemplatesForm = true;
      userSettings.settings = action.payload;
      userSettings.settings.templates = userSettings.settings.templates.map(
        (item, i) => {
          item.active = i === userSettings.settings.templates.length - 1;
          return item;
        },
      );
    },
    templateRemoved: (userSettings, action) => {
      userSettings.settings = action.payload;
      userSettings.showTemplatesForm = false;
      userSettings.isAdding = false;
    },
    templateUpdated: (userSettings, action) => {
      const { index, highLevelData } = action.payload;
      userSettings.settings.templates[index].highLevel = highLevelData;
    },
    showTemplatesFormUpdated: (userSettings, action) => {
      userSettings.showTemplatesForm = action.payload;
    },
    sectionTypesFetched: (userSettings, action) => {
      userSettings.isFetching = false;
      userSettings.sectionTypes = action.payload.sections;
    },
    sectionTypeSelected: (userSettings, action) => {
      const { templates } = userSettings.settings;
      userSettings.sectionTypes = userSettings.sectionTypes.map((item) => {
        item.active = item.sectionId === action.payload;
        return item;
      });
      const activeTemplateIndex = templates.findIndex((item) => item.active);
      userSettings.settings.templates[activeTemplateIndex].activityModel =
        templates[activeTemplateIndex].activityModel.map((item) => {
          item.active = false;
          return item;
        });
    },
    showOperationsFormUpdated: (userSettings, action) => {
      userSettings.showOperationsForm = action.payload;
    },
    showDeleteOperationsModalUpdated: (userSettings, action) => {
      userSettings.showDeleteOperationsModal = action.payload.visible;
      userSettings.selectedId = action.payload.selectedId;
    },
    operationSaveRequested: (userSettings, action) => {
      const { templateIndex, operation } = action.payload;
      userSettings.isAdding = true;
      userSettings.settings.templates[templateIndex].activityModel.push(
        operation,
      );
      userSettings.settings.templates[templateIndex].activityModel.map(
        (item) => (item.active = item.id === operation.id),
      );
    },
    operationSaved: (userSettings) => {
      userSettings.isAdding = false;
      userSettings.showOperationsForm = true;
    },
    addOperationFromSectionsDataSaved: (userSettings) => {
      userSettings.isAdding = false;
      userSettings.showAddOperationFromSectionsDataModal = false;
    },
    operationUpdateRequested: (userSettings, action) => {
      const { templateIndex, operationIndex, data } = action.payload;
      userSettings.isAdding = true;
      userSettings.settings.templates[templateIndex].activityModel[
        operationIndex
      ] = data;
    },
    operationUpdated: (userSettings) => {
      userSettings.isAdding = false;
      userSettings.showOperationsForm = false;
    },
    operationSelected: (userSettings, action) => {
      const { templates } = userSettings.settings;
      const activeTemplateIndex = templates.findIndex((item) => item.active);
      userSettings.settings.templates[activeTemplateIndex].activityModel =
        templates[activeTemplateIndex].activityModel.map((item) => {
          item.active = item.id === action.payload;
          return item;
        });
    },
    operationRemoved: (userSettings, action) => {
      const { templateIndex, operationId } = action.payload;
      const { activityModel } = userSettings.settings.templates[templateIndex];
      userSettings.settings.templates[
        action.payload.templateIndex
      ].activityModel = activityModel?.filter(
        (operation) => operation.id !== operationId,
      );
      userSettings.isAdding = false;
      userSettings.showDeleteOperationsModal = false;
    },
    codesRequested: (userSettings) => {
      userSettings.codes = [];
    },
    codesReceived: (userSettings, action) => {
      userSettings.codes = action.payload.codes;
    },
    activitySaveRequested: (userSettings, action) => {
      const { templateIndex, operationIndex, activity } = action.payload;
      const { templates } = userSettings.settings;
      userSettings.isAdding = true;
      templates[templateIndex].activityModel[operationIndex].activities.push(
        activity,
      );
      templates[templateIndex].activityModel[operationIndex].activities =
        templates[templateIndex].activityModel[operationIndex].activities.map(
          (item) => {
            item.active = item.id === activity.id;
            return item;
          },
        );
    },
    activitySaved: (userSettings) => {
      userSettings.isAdding = false;
      userSettings.showActivityForm = true;
    },
    activityRemoved: (userSettings, action) => {
      const { templateIndex, operationIndex, deleteResult } = action.payload;
      userSettings.settings.templates[templateIndex].activityModel[
        operationIndex
      ].activities = deleteResult;

      userSettings.isAdding = false;
      userSettings.showDeleteActivitiesModal = false;
    },
    showDeleteActivitiesModalUpdated: (userSettings, action) => {
      userSettings.showDeleteActivitiesModal = action.payload.visible;
      userSettings.selectedId = action.payload.selectedId;
    },
    activitiesReorderRequested: (userSettings, action) => {
      const { templateIndex, operationIndex, activities } = action.payload;
      userSettings.settings.templates[templateIndex].activityModel[
        operationIndex
      ].activities = activities;
    },
    showActivitiesForm: (userSettings, action) => {
      const { templates } = userSettings.settings;
      const activeTemplateIndex = templates.findIndex((item) => item.active);
      const activeOperationIndex = templates[
        activeTemplateIndex
      ].activityModel.findIndex((item) => item.active);
      userSettings.settings.templates[activeTemplateIndex].activityModel[
        activeOperationIndex
      ].activities = templates[activeTemplateIndex].activityModel[
        activeOperationIndex
      ].activities.map((item) => {
        item.active = item.id === action.payload;
        return item;
      });
      userSettings.showActivityForm = true;
    },
    closeActivitiesForm: (userSettings) => {
      userSettings.showActivityForm = false;
    },
    activityUpdateRequested: (userSettings, action) => {
      const { templateIndex, operationIndex, settingsData } = action.payload;
      userSettings.isAdding = true;
      userSettings.settings.templates[templateIndex].activityModel[
        operationIndex
      ].activities =
        settingsData.templates[templateIndex].activityModel[
          operationIndex
        ].activities;
    },
    activityUpdated: (userSettings) => {
      userSettings.isAdding = false;
      userSettings.showActivityForm = false;
    },
    showImportOperationModalUpdated: (userSettings, action) => {
      userSettings.showImportOperationModal = action.payload;
    },
    operationsImportRequested: (userSettings, action) => {
      const { templateIndex, mergedOperations } = action.payload;
      userSettings.isAdding = true;
      userSettings.settings.templates[templateIndex].activityModel.push(
        ...mergedOperations,
      );
    },
    operationsImported: (userSettings) => {
      userSettings.isAdding = false;
      userSettings.showImportOperationModal = false;
    },

    operationReorder: (userSettings, action) => {
      const { response, activeTemplateIndex } = action.payload;
      userSettings.settings.templates[activeTemplateIndex].activityModel =
        response.templates[activeTemplateIndex].activityModel;
      userSettings.showTemplatesForm = true;
      userSettings.isAdding = false;
    },

    templateReorder: (userSettings, action) => {
      const { templates } = action.payload;
      userSettings.settings.templates = templates;
      userSettings.showTemplatesForm = false;
      userSettings.isAdding = false;
    },
    addCostItemRequested: (userSettings, action) => {
      const { templateIndex, costItem } = action.payload;
      userSettings.isAdding = true;
      userSettings.settings.templates[templateIndex].costSetup.costItem.push(
        costItem,
      );
      userSettings.selectedId = costItem.costItemId;
    },
    addCostItemSaved: (userSettings) => {
      userSettings.isAdding = false;
    },
    costItemRemoved: (userSettings, action) => {
      const { costItemIndex, templateIndex } = action.payload;
      const { costItem } =
        userSettings.settings.templates[templateIndex].costSetup;
      costItem.splice(costItemIndex, 1);
      userSettings.settings.templates[templateIndex].costSetup.costItem =
        costItem;
      userSettings.isAdding = false;
    },
    showDeleteCostItemModalUpdated: (userSettings, action) => {
      userSettings.showDeleteCostItemModal = action.payload.visible;
      userSettings.selectedId = action.payload.selectedId;
    },
    costItemSelected: (userSettings, action) => {
      const { templates } = userSettings.settings;
      const activeTemplateIndex = templates.findIndex((item) => item.active);
      userSettings.selectedId = userSettings.settings.templates[
        activeTemplateIndex
      ].costSetup.costItem.find(
        (item) => item.costItemId === action.payload,
      ).costItemId;
    },
    currenciesRequested: (userSettings) => {
      userSettings.currencies = [];
    },
    currenciesReceived: (userSettings, action) => {
      const { currencies } = action.payload;
      userSettings.currencies = currencies;
    },
    costItemUpdatedRequested: (userSettings, action) => {
      const { templateIndex, costItemIndex, data } = action.payload;
      userSettings.isAdding = true;
      userSettings.settings.templates[templateIndex].costSetup.costItem[
        costItemIndex
      ] = data;
    },
    costItemUpdated: (userSettings) => {
      userSettings.isAdding = false;
    },
    addCostCategoryRequested: (userSettings, action) => {
      const { templateIndex, costCategory } = action.payload;
      userSettings.isAdding = true;
      userSettings.settings.templates[
        templateIndex
      ].costSetup.costCategory.push(costCategory);
      userSettings.selectedId = costCategory.costCategoryId;
    },
    addCostCategorySaved: (userSettings) => {
      userSettings.isAdding = false;
    },
    costCategorySelected: (userSettings, action) => {
      const { templates } = userSettings.settings;
      const activeTemplateIndex = templates.findIndex((item) => item.active);
      userSettings.selectedId = userSettings.settings.templates[
        activeTemplateIndex
      ].costSetup.costCategory.find(
        (item) => item.costCategoryId === action.payload,
      ).costCategoryId;
    },
    costCategoryUpdatedRequested: (userSettings, action) => {
      const { templateIndex, costCategoryIndex, data } = action.payload;
      userSettings.isAdding = true;
      userSettings.settings.templates[templateIndex].costSetup.costCategory[
        costCategoryIndex
      ] = data;
    },
    costCategoryUpdated: (userSettings) => {
      userSettings.isAdding = false;
    },
    showDeleteCostCategoryModalUpdated: (userSettings, action) => {
      userSettings.showDeleteCostCategoryModal = action.payload.visible;
      userSettings.selectedId = action.payload.selectedId;
    },
    costCategoryRemoved: (userSettings, action) => {
      const { templateIndex, filteredCostSetup } = action.payload;
      userSettings.settings.templates[templateIndex].costSetup =
        filteredCostSetup;
      userSettings.isAdding = false;
      userSettings.showDeleteActivitiesModal = false;
    },
    costSetupReorderRequested: (userSettings, action) => {
      const { data, templateIndex } = action.payload;
      userSettings.settings.templates[templateIndex].costSetup = data;
    },
    kpiSaved: (userSettings, action) => {
      userSettings.isAdding = false;
      userSettings.showKpiForm = true;
      userSettings.settings = action.payload;
      userSettings.settings.kpi = userSettings.settings.kpi.map((item, i) => {
        item.active = i === userSettings.settings.kpi.length - 1;
        return item;
      });
    },
    userKpiSelected: (userSettings, action) => {
      userSettings.settings.kpi = userSettings.settings.kpi.map((item) => {
        item.active = item.id === action.payload;
        return item;
      });
      userSettings.showKpiForm = true;
    },
    kpiRemoved: (userSettings, action) => {
      userSettings.settings = action.payload;
      userSettings.showKpiForm = false;
      userSettings.showDeleteKpiModal = false;
      userSettings.isAdding = false;
    },
    showKpiFormUpdated: (userSettings, action) => {
      userSettings.showKpiForm = action.payload;
    },
    showDeleteKpiModalUpdated: (userSettings, action) => {
      userSettings.showDeleteKpiModal = action.payload;
    },
    kpiUpdated: (userSettings, action) => {
      const { kpiIndex, kpi } = action.payload;
      userSettings.settings.kpi[kpiIndex] = kpi;
      userSettings.settings.kpi[kpiIndex].active = true;
    },
    showAddOperationFromSectionsDataModalUpdated: (userSettings, action) => {
      userSettings.showAddOperationFromSectionsDataModal = action.payload;
    },
    showAddActivityFromSectionsDataModalUpdated: (userSettings, action) => {
      userSettings.showAddActivityFromSectionsDataModal = action.payload;
    },
    addActivityFromSectionsDataSaved: (userSettings) => {
      userSettings.isAdding = false;
      userSettings.showAddActivityFromSectionsDataModal = false;
    },
  },
});

export const {
  userSettingsRequested,
  userSettingsReceived,
  userSettingsSaveRequested,
  userSettingsSaveFailed,
  userSettingsSaved,
  templateSelected,
  templatesSaved,
  templateRemoved,
  templateUpdated,
  showTemplatesFormUpdated,
  sectionTypeSelected,
  operationSelected,
  operationSaveRequested,
  operationSaved,
  operationUpdateRequested,
  operationUpdated,
  operationReorder,
  codesRequested,
  codesReceived,
  showOperationsFormUpdated,
  showDeleteOperationsModalUpdated,
  operationRemoved,
  activitySaveRequested,
  activitySaved,
  activityRemoved,
  showDeleteActivitiesModalUpdated,
  activitiesReorderRequested,
  showActivitiesForm,
  closeActivitiesForm,
  activityUpdateRequested,
  activityUpdated,
  showImportOperationModalUpdated,
  operationsImportRequested,
  operationsImported,
  templateReorder,
  addCostItemRequested,
  addCostItemSaved,
  costItemRemoved,
  showDeleteCostItemModalUpdated,
  costItemSelected,
  currenciesRequested,
  currenciesReceived,
  costItemUpdatedRequested,
  costItemUpdated,
  addCostCategoryRequested,
  addCostCategorySaved,
  costCategorySelected,
  costCategoryUpdatedRequested,
  costCategoryUpdated,
  showDeleteCostCategoryModalUpdated,
  costCategoryRemoved,
  costSetupReorderRequested,
  kpiSaved,
  userKpiSelected,
  kpiRemoved,
  showKpiFormUpdated,
  showDeleteKpiModalUpdated,
  kpiUpdated,
  sectionTypesFetched,
  showAddOperationFromSectionsDataModalUpdated,
  addOperationFromSectionsDataSaved,
  showAddActivityFromSectionsDataModalUpdated,
  addActivityFromSectionsDataSaved,
} = userSettings.actions;
export default userSettings.reducer;

export const wellConfigurations = [
  {
    label: translations.settings_offshore,
    value: wellConfiguration.OFFSHORE,
  },
  {
    label: translations.settings_onshore,
    value: wellConfiguration.ONSHORE,
  },
];

export const drillingUnits = [
  {
    label: translations.settings_semiSubmersible,
    value: drillingUnit.SEMI_SUBMERSIBLE,
  },
  {
    label: translations.settings_platformJackup,
    value: drillingUnit.PLATFORM_JACKUP,
  },
  {
    label: translations.settings_tlpHybrid,
    value: drillingUnit.TLP_HYBRID,
  },
  {
    label: translations.settings_landRig,
    value: drillingUnit.LAND_RIG,
  },
];

/**
 * Get settings
 */
export const getUserSettings = () =>
  apiCallBegan({
    url: '/api/settings/user',
    method: 'GET',
    onStart: userSettingsRequested.type,
    onSuccess: userSettingsReceived.type,
  });

/**
 * Save settings
 *
 * @param data
 */
export const saveUserSettings = (data) =>
  apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data,
    onStart: userSettingsSaveRequested.type,
    onSuccess: (response) => ({
      type: userSettingsSaved.type,
      payload: response,
    }),
  });

/**
 * Add initial template
 *
 * @param name
 * @param settings
 */
export const addInitialTemplate = (name, settings) => {
  const data = { ...settings };
  const { templates } = data;
  const newName = nextName(
    templates.map((template) => template.highLevel.name),
    name,
  );
  const template = {
    templateId: uuidv4(),
    highLevel: { name: newName, ...initialTemplate.highLevel },
    activityModel: [],
    costSetup: {},
  };
  data.templates = [...data.templates, template];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data.templates = data.templates.map(({ active, ...rest }) => rest);

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data,
    onStart: userSettingsSaveRequested.type,
    onSuccess: (response) => ({
      type: templatesSaved.type,
      payload: response,
    }),
  });
};

/**
 * Remove template
 *
 * @param index
 * @param settings
 */
export const removeTemplate = (index, settings) => {
  const data = cloneDeep(settings);
  data.templates.splice(index, 1);

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data,
    onStart: userSettingsSaveRequested.type,
    onSuccess: (response) => ({
      type: templateRemoved.type,
      payload: response,
    }),
  });
};

/**
 * Update template
 *
 * @param index
 * @param settings
 * @param highLevelData
 */
export const updateTemplate = (index, settings, highLevelData) => {
  const data = cloneDeep(settings);
  if (
    data.templates.some(
      (template, i) =>
        template.highLevel.name.toUpperCase().trim() ===
          highLevelData.name.toUpperCase().trim() && i !== index,
    )
  ) {
    toast({
      message: {
        type: 'Error',
        content: i18n.t(translations.settings_nameMustBeUnique, {
          type: i18n.t(translations.template),
          name: highLevelData.name,
        }),
      },
    });
    highLevelData.name = data.templates[index].highLevel.name;
  }
  data.templates[index].highLevel = highLevelData;

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data,
    onSuccess: () => ({
      type: templateUpdated.type,
      payload: { index, highLevelData },
    }),
  });
};

/**
 * Add initial operation
 *
 * @param name
 * @param settings
 */
export const addInitialOperation = (name, settings) => {
  const data = cloneDeep(settings);
  const templateIndex = data.settings.templates.findIndex(
    (item) => item.active,
  );
  const sectionType = data.sectionTypes.find((item) => item.active);
  const operation = {
    ...initialOperation,
    id: uuidv4(),
    sectionType: sectionType.sectionId,
    name,
  };
  data.settings.templates[templateIndex].activityModel.push(operation);

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data: data.settings,
    onStart: () => ({
      type: operationSaveRequested.type,
      payload: { templateIndex, operation },
    }),
    onSuccess: (response) => ({
      type: operationSaved.type,
      payload: response,
    }),
  });
};

/**
 *  Update operation
 *
 * @param data
 * @param settings
 */
export const updateOperation = (data, settings) => {
  const settingsData = cloneDeep(settings);
  const templateIndex = settingsData.templates.findIndex((item) => item.active);
  const operationIndex = settingsData.templates[
    templateIndex
  ].activityModel.findIndex((item) => item.active);
  settingsData.templates[templateIndex].activityModel[operationIndex] = data;

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data: settingsData,
    onStart: () => ({
      type: operationUpdateRequested.type,
      payload: { templateIndex, operationIndex, data },
    }),
    onSuccess: (response) => ({
      type: operationUpdated.type,
      payload: response,
    }),
  });
};

/**
 * Remove operation
 *
 * @param index
 * @param settings
 */
export const removeOperation = (operationId, settings) => {
  const data = cloneDeep(settings);
  const templateIndex = data.templates.findIndex((item) => item.active);
  const filteredOperation = data.templates[templateIndex].activityModel.filter(
    (operation) => operation.id !== operationId,
  );
  data.templates[templateIndex].activityModel = filteredOperation;

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data,
    onStart: userSettingsSaveRequested.type,
    onSuccess: () => ({
      type: operationRemoved.type,
      payload: { operationId, templateIndex },
    }),
  });
};

/**
 *  Get active operation codes for operation form
 */
export const getOperationCodes = () =>
  apiCallBegan({
    url: '/api/user-settings/active-codes',
    method: 'GET',
    onStart: codesRequested.type,
    onSuccess: codesReceived.type,
  });

/**
 * Add initial activity
 *
 * @param name
 * @param settings
 * @param parentId
 */
export const addInitialActivity = (
  name,
  settings,
  parentId = 0,
  sectionsOperationActivityId = null,
) => {
  const data = cloneDeep(settings);
  const templateIndex = data.templates.findIndex((item) => item.active);
  const activity = {
    ...initialActivity,
    id: uuidv4(),
    parentId,
    name,
    sectionsOperationActivityId,
  };
  const operationIndex = data.templates[templateIndex].activityModel.findIndex(
    (item) => item.active,
  );
  data.templates[templateIndex].activityModel[operationIndex].activities.push(
    activity,
  );

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data,
    onStart: () => ({
      type: activitySaveRequested.type,
      payload: { templateIndex, operationIndex, activity },
    }),
    onSuccess: (response) => ({
      type: activitySaved.type,
      payload: response,
    }),
  });
};

/**
 * Remove activity
 *
 * @param id
 * @param settings
 */
export const removeActivity = (id, settings) => {
  const data = cloneDeep(settings);
  const templateIndex = data.templates.findIndex((item) => item.active);
  const operationIndex = data.templates[templateIndex].activityModel.findIndex(
    (item) => item.active,
  );
  const { activities } =
    data.templates[templateIndex].activityModel[operationIndex];
  const deleteActivityById = (id, activities) => {
    const index = activities.findIndex((item) => item.id === id);

    if (index !== -1) {
      activities.splice(index, 1);
      const childItems = activities.filter((item) => item.parentId === id);
      childItems.forEach((childItem) =>
        deleteActivityById(childItem.id, activities),
      );
    }

    return activities;
  };
  const deleteResult = deleteActivityById(id, activities);
  data.templates[templateIndex].activityModel[operationIndex].activities =
    deleteResult;

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data,
    onStart: userSettingsSaveRequested.type,
    onSuccess: () => ({
      type: activityRemoved.type,
      payload: { templateIndex, operationIndex, deleteResult },
    }),
  });
};

/**
 * Reorder activities
 *
 * @param orderedTasks
 * @param settings
 */
export const reorderActivities = (orderedTasks, settings) => {
  const data = cloneDeep(settings);
  const templateIndex = data.templates.findIndex((item) => item.active);
  const operationIndex = data.templates[templateIndex].activityModel.findIndex(
    (item) => item.active,
  );

  const orderedTasksMap = orderedTasks.map((task, i) => ({
    id: task.id,
    parentId: task.parent,
    position: i,
  }));

  const activities = data.templates[templateIndex].activityModel[
    operationIndex
  ].activities.map((item) => {
    const reorderedTask = orderedTasksMap.find((task) => task.id === item.id);

    if (reorderedTask) {
      return {
        ...item,
        parentId: reorderedTask.parentId,
        position: reorderedTask.position,
      };
    }
    return item;
  });
  activities.sort((a, b) => a.position - b.position);
  data.templates[templateIndex].activityModel[operationIndex].activities =
    activities;

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data,
    onStart: () => ({
      type: activitiesReorderRequested.type,
      payload: { templateIndex, operationIndex, activities },
    }),
  });
};

/**
 * Update activity
 *
 * @param data
 * @param settings
 */
export const updateActivity = (data, branches, settings) => {
  const settingsData = cloneDeep(settings);
  const templateIndex = settingsData.templates.findIndex((item) => item.active);
  const operationIndex = settingsData.templates[
    templateIndex
  ].activityModel.findIndex((item) => item.active);
  const activityIndex = settingsData.templates[templateIndex].activityModel[
    operationIndex
  ].activities.findIndex((item) => item.active);
  settingsData.templates[templateIndex].activityModel[
    operationIndex
  ].activities[activityIndex] = data;

  const activities = settingsData.templates[templateIndex].activityModel[
    operationIndex
  ].activities.filter(
    (activity) => !(activity?.isBranch && activity?.parentId === data.id),
  );

  settingsData.templates[templateIndex].activityModel[
    operationIndex
  ].activities = [...activities, ...(data?.withBranch ? branches : [])];

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data: settingsData,
    onStart: () => ({
      type: activityUpdateRequested.type,
      payload: { templateIndex, operationIndex, settingsData },
    }),
    onSuccess: (response) => ({
      type: activityUpdated.type,
      payload: response,
    }),
  });
};

export const importOperations = (data, templateIndex, section, settings) => {
  const settingsData = cloneDeep(settings);
  const mergedOperations = data.operations.map((id) => {
    const copied = settingsData.templates[data.template].activityModel.find(
      (operation) => operation.id === id,
    );
    return { ...copied, id: uuidv4(), sectionType: section.sectionId };
  });
  settingsData.templates[templateIndex].activityModel.push(...mergedOperations);

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data: settingsData,
    onStart: () => ({
      type: operationsImportRequested.type,
      payload: { templateIndex, mergedOperations },
    }),
    onSuccess: (response) => ({
      type: operationsImported.type,
      payload: response,
    }),
  });
};

/**
 * Reorder template
 *
 * @param fromIndex
 * @param toIndex
 * @param settings
 */
export const reorderTemplate = (fromIndex, toIndex, settings) => {
  if (fromIndex === toIndex) return;
  const data = cloneDeep(settings);
  const [movedItem] = data.templates.splice(fromIndex, 1);
  data.templates.splice(toIndex, 0, movedItem);

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data,
    onStart: userSettingsSaveRequested.type,
    onSuccess: (response) => ({
      type: templateReorder.type,
      payload: response,
    }),
  });
};

/**
 * Reorder operation
 *
 * @param settings
 * @param reorder
 */

export const reorderOperationsInSettings = (reorder, settings) => {
  const result = reorderOperation(reorder, settings);

  if (!result) {
    return;
  }

  const { updatedSettings, activeTemplateIndex } = result;

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data: updatedSettings,
    onStart: userSettingsSaveRequested.type,
    onSuccess: (response) => {
      return {
        type: operationReorder.type,
        payload: { response, activeTemplateIndex },
      };
    },
  });
};

/**
 * Add initial cost item
 *
 * @param name
 * @param settings
 * @param parentId
 */
export const addCostItem = (name, settings, parentId = 0) => {
  const data = cloneDeep(settings);
  const templateIndex = data.settings.templates.findIndex(
    (item) => item.active,
  );
  const costItem = {
    ...initialCostItem,
    costItemId: uuidv4(),
    parentId,
    name,
  };
  data.settings.templates[templateIndex].costSetup.costItem.push(costItem);

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data: data.settings,
    onStart: () => ({
      type: addCostItemRequested.type,
      payload: { templateIndex, costItem },
    }),
    onSuccess: addCostItemSaved.type,
  });
};

/**
 * Remove cost item
 *
 * @param name
 * @param settings
 * @param parentId
 */
export const removeCostItem = (id, settings) => {
  const data = cloneDeep(settings);
  const templateIndex = data.templates.findIndex((item) => item.active);
  const costItemIndex = data.templates[
    templateIndex
  ].costSetup.costItem.findIndex((item) => item.costItemId === id);
  data.templates[templateIndex].costSetup.costItem.splice(costItemIndex, 1);

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data,
    onStart: userSettingsSaveRequested.type,
    onSuccess: () => ({
      type: costItemRemoved.type,
      payload: { costItemIndex, templateIndex },
    }),
  });
};

/**
 *  Update cost item
 *
 * @param data
 * @param settings
 */
export const updateCostItem = (data, settings, selectedId) => {
  const settingsData = cloneDeep(settings);
  const templateIndex = settingsData.templates.findIndex((item) => item.active);
  const costItemIndex = settingsData.templates[
    templateIndex
  ].costSetup.costItem.findIndex((item) => item.costItemId === selectedId);
  settingsData.templates[templateIndex].costSetup.costItem[costItemIndex] =
    data;
  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data: settingsData,
    onStart: () => ({
      type: costItemUpdatedRequested.type,
      payload: { templateIndex, costItemIndex, data },
    }),
    onSuccess: costItemUpdated.type,
  });
};

/**
 *  Get currencies operation codes for operation form
 */
export const getCurrencies = () =>
  apiCallBegan({
    url: '/api/currencies',
    method: 'GET',
    onStart: currenciesRequested.type,
    onSuccess: currenciesReceived.type,
  });

/**
 *  Add cost category
 *
 * @param data
 * @param settings
 */
export const addCostCategory = (data, settings) => {
  const { name, parentId } = data;
  const settingsData = cloneDeep(settings);
  const templateIndex = settingsData.settings.templates.findIndex(
    (item) => item.active,
  );
  const costCategory = {
    costCategoryId: uuidv4(),
    parentId,
    name,
  };
  settingsData.settings.templates[templateIndex].costSetup.costCategory.push(
    costCategory,
  );
  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data: settingsData.settings,
    onStart: () => ({
      type: addCostCategoryRequested.type,
      payload: { templateIndex, costCategory },
    }),
    onSuccess: addCostCategorySaved.type,
  });
};

/**
 *  Update cost category
 *
 * @param data
 * @param settings
 */
export const updateCostCategory = (data, settings, selectedId) => {
  const settingsData = cloneDeep(settings);
  const templateIndex = settingsData.templates.findIndex((item) => item.active);
  const costCategoryIndex = settingsData.templates[
    templateIndex
  ].costSetup.costCategory.findIndex(
    (item) => item.costCategoryId === selectedId,
  );
  settingsData.templates[templateIndex].costSetup.costCategory[
    costCategoryIndex
  ] = data;
  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data: settingsData,
    onStart: () => ({
      type: costCategoryUpdatedRequested.type,
      payload: { templateIndex, costCategoryIndex, data },
    }),
    onSuccess: costCategoryUpdated.type,
  });
};

/**
 *  Remove cost category
 *
 * @param data
 * @param settings
 */
export const removeCostCategory = (costCategoryId, settings) => {
  const settingsData = cloneDeep(settings);
  const templateIndex = settingsData.settings.templates.findIndex(
    (item) => item.active,
  );
  const { costSetup } = settingsData.settings.templates[templateIndex];
  const filteredCostSetup = findConnectedCategories(costSetup, costCategoryId);
  settingsData.settings.templates[templateIndex].costSetup = filteredCostSetup;
  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data: settingsData.settings,
    onStart: userSettingsSaveRequested.type,
    onSuccess: () => ({
      type: costCategoryRemoved.type,
      payload: { templateIndex, filteredCostSetup },
    }),
  });
};

/**
 * Reorder cost elements
 *
 * @param orderedElements
 * @param list
 * @param settings
 *
 */
export const reorderElements = (orderedElements, list, settings) => {
  const settingsData = cloneDeep(settings);
  const templateIndex = settingsData.templates.findIndex((item) => item.active);
  const orderedElementsData = orderedElements.map((orderedElement, i) => ({
    ...(orderedElement.itemType === 'category'
      ? { costCategoryId: orderedElement.id }
      : { costItemId: orderedElement.id }),
    parentId: orderedElement.parent || null,
    position: i,
  }));
  const costSetupOrdered = {
    costItem: orderedElementsData.filter((item) => item.costItemId),
    costCategory: orderedElementsData.filter((item) => item.costCategoryId),
  };
  const costSetupUnordered = settingsData.templates[templateIndex].costSetup;
  const data = {
    costItem: [
      ...costSetupUnordered.costItem.map((oldItem) => {
        const newItem = costSetupOrdered.costItem.find(
          (item) => item.costItemId === oldItem.costItemId,
        );
        return newItem ? { ...oldItem, ...newItem } : oldItem;
      }),
      ...costSetupOrdered.costItem.filter(
        (newItem) =>
          !costSetupUnordered.costItem.find(
            (oldItem) => oldItem.costItemId === newItem.costItemId,
          ),
      ),
    ],
    costCategory: [
      ...costSetupUnordered.costCategory.map((oldCategory) => {
        const newCategory = costSetupOrdered.costCategory.find(
          (category) => category.costCategoryId === oldCategory.costCategoryId,
        );
        return newCategory ? { ...oldCategory, ...newCategory } : oldCategory;
      }),
      ...costSetupOrdered.costCategory.filter(
        (newCategory) =>
          !costSetupUnordered.costCategory.find(
            (oldCategory) =>
              oldCategory.costCategoryId === newCategory.costCategoryId,
          ),
      ),
    ],
  };

  settingsData.templates[templateIndex].costSetup = data;

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data: settingsData,
    onStart: () => ({
      type: costSetupReorderRequested.type,
      payload: { data, templateIndex },
    }),
  });
};

/**
 * Duplicate Template
 *
 * @param settings
 * @param index
 *
 */
export const duplicateTemplate = (settings, index) => {
  const data = cloneDeep(settings);
  const { templates } = data;
  const duplicatedTemplate = cloneDeep(templates[index]);
  const newName = nextName(
    templates.map((template) => template.highLevel.name),
    duplicatedTemplate.highLevel.name,
  );

  if (!duplicatedTemplate.costSetup) {
    duplicatedTemplate.costSetup = initialTemplate.costSetup;
  }

  const { costCategory, costItem } = duplicatedTemplate?.costSetup;

  costItem.forEach((item) => {
    const newItemId = uuidv4();
    item.costItemId = newItemId;
  });

  costCategory.forEach((category) => {
    const { costCategoryId } = category;
    const newCategoryId = uuidv4();
    category.costCategoryId = newCategoryId;

    updateCostSetupIds(costCategory, costCategoryId, newCategoryId);
    updateCostSetupIds(costItem, costCategoryId, newCategoryId);
  });

  duplicatedTemplate.activityModel = updateActivityIds(
    duplicatedTemplate.activityModel,
  );

  duplicatedTemplate.highLevel.name = newName;
  duplicatedTemplate.templateId = uuidv4();
  templates.push(duplicatedTemplate);

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data,
    onStart: userSettingsSaveRequested.type,
    onSuccess: (response) => ({
      type: templatesSaved.type,
      payload: response,
    }),
  });
};

/**
 * Upload Template
 *
 * @param settings
 * @param template
 *
 */
export const addTemplateFromUpload = (settings, template) => {
  const data = cloneDeep(settings);
  const { templates } = data;
  templates.push({ ...template, templateId: uuidv4() });

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data,
    onStart: userSettingsSaveRequested.type,
    onSuccess: (response) => ({
      type: templatesSaved.type,
      payload: response,
    }),
    onError: userSettingsSaveFailed.type,
  });
};

/**
 * Adds an initial Key Performance Indicator (KPI) to the user settings.
 *
 * @param {string} name - The name of the KPI.
 * @param {object} settings - The user settings.
 * @returns {Object} The API call action object.
 */
export const addInitialUserKpi = (name, settings) => {
  const data = cloneDeep(settings);
  const { kpi: kpis } = data;
  const newName = nextName(
    kpis.map((kpi) => kpi.name),
    name,
  );

  const kpi = {
    ...initialKpi,
    name: newName,
    id: uuidv4(),
  };
  data.kpi = [...data.kpi, kpi];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data.kpi = data.kpi.map(({ active, ...rest }) => rest);

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data,
    onStart: userSettingsSaveRequested.type,
    onSuccess: (response) => ({
      type: kpiSaved.type,
      payload: response,
    }),
  });
};

/**
 * Removes a KPI from user settings.
 *
 * @param {string} id - The ID of the KPI to be removed.
 * @param {Object} settings - The user settings object.
 * @returns {Object} The API call action object.
 */
export const removeUserKpi = (id, settings) => {
  const data = cloneDeep(settings);
  const index = data.kpi.findIndex((item) => item.id === id);
  data.kpi.splice(index, 1);

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data,
    onStart: userSettingsSaveRequested.type,
    onSuccess: (response) => ({
      type: kpiRemoved.type,
      payload: response,
    }),
  });
};

/**
 * Updates the KPI with the given value and sends an API request to update the settings.
 *
 * @param {Object} kpi - The new KPI value to update.
 * @param {Object} settings - The current settings object to update.
 * @returns {Object} The API call action object.
 */
export const updateUserKpi = (kpi, settings) => {
  const data = cloneDeep(settings);
  const kpiIndex = data.kpi.findIndex((item) => item.active);

  if (
    data.kpi.some(
      (k) =>
        k.name.toUpperCase().trim() === kpi.name.toUpperCase().trim() &&
        !k.active,
    )
  ) {
    toast({
      message: {
        type: 'Error',
        content: i18n.t(translations.settings_nameMustBeUnique, {
          type: i18n.t(translations.kpi),
          name: kpi.name,
        }),
      },
    });
    kpi.name = data.kpi[kpiIndex].name;
  }
  data.kpi[kpiIndex] = kpi;

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data,
    onSuccess: () => ({
      type: kpiUpdated.type,
      payload: { kpiIndex, kpi },
    }),
  });
};

/**
 * Retrieves the section types from the server.
 *
 * @returns {Object} The API call action object.
 */
export const getSectionTypes = () =>
  apiCallBegan({
    url: '/api/operations/sections',
    method: 'GET',
    onSuccess: (response) => ({
      type: sectionTypesFetched.type,
      payload: response,
    }),
  });

/**
 * Add operation from sections data structure.
 *
 * @param {object} operation - The operation object.
 * @param {object} settings - The settings object.
 * @param {number} sectionId - The section ID.
 *
 * @returns {object} - Returns the result of the API call.
 */
export const addOperationFromSectionsData = (
  operation,
  settings,
  sectionId,
) => {
  const data = cloneDeep(settings);
  const templateIndex = data.templates.findIndex((item) => item.active);
  const operationData = {
    ...operation,
    id: uuidv4(),
    sectionType: sectionId,
  };

  data.templates[templateIndex].activityModel.push(operationData);

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data,
    onStart: () => ({
      type: operationSaveRequested.type,
      payload: { templateIndex, operation: operationData },
    }),
    onSuccess: (response) => ({
      type: addOperationFromSectionsDataSaved.type,
      payload: response,
    }),
  });
};

/**
 * Adds an activity from sections data.
 *
 * @param {Object} activity - The activity data to add.
 * @param {Object} settings - The settings data.
 * @returns {Object} - The result of the API call.
 */
export const addActivityFromSectionsData = (activity, settings) => {
  const data = cloneDeep(settings);
  const templateIndex = data.templates.findIndex((item) => item.active);
  const activityData = {
    ...activity,
    id: uuidv4(),
    parentId: 0,
  };
  const operationIndex = data.templates[templateIndex].activityModel.findIndex(
    (item) => item.active,
  );
  data.templates[templateIndex].activityModel[operationIndex].activities.push(
    activityData,
  );

  return apiCallBegan({
    url: '/api/settings/user',
    method: 'POST',
    data,
    onStart: () => ({
      type: activitySaveRequested.type,
      payload: { templateIndex, operationIndex, activity: activityData },
    }),
    onSuccess: (response) => ({
      type: addActivityFromSectionsDataSaved.type,
      payload: response,
    }),
  });
};
