import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Card,
  Button,
  Flex,
  Menu,
  Heading,
  Spacer,
  Badge,
} from '@oliasoft-open-source/react-ui-library';
import { displayNumber, round } from '@oliasoft-open-source/units';
import { deleteKpi } from '~store/entities/campaigns/campaigns';
import translations from '~src/internationalisation/translation-map.json';

const KpiCard = ({ kpi, campaign, deleteKpi }) => {
  const { t } = useTranslation();

  const targetInequalityMap = {
    moreThan: '>',
    lessOrEqual: '<=',
  };
  const targetTitle = () => {
    if (kpi.isTarget) {
      return `${t(translations.campaigns_target)} ${
        targetInequalityMap[kpi.targetInequality]
      } ${kpi.target} ${kpi.unit}`;
    } else {
      return t(translations.campaigns_noTargetSet);
    }
  };

  return (
    <div className="kpi-card">
      <Card>
        <div className="kpi-card-inner">
          <Flex direction="column" alignItems="center">
            <Heading>{kpi.name}</Heading>
            <Spacer height={10} />
            <div className="kpi-result">
              {displayNumber(round(kpi.value || 0, 0), {
                scientific: false,
              })}
            </div>
            <Spacer height={10} />
            <Badge title={targetTitle()} color="var(--color-background-info)" />
          </Flex>
        </div>
      </Card>
      <div className="kpi-card-menu">
        <Menu
          menu={{
            component: <Button colored icon="menu" round small />,
            label: 'Select',
            sections: [
              {
                label: 'remove',
                type: 'Option',
                onClick: () => deleteKpi(kpi.id, campaign.campaignId),
              },
            ],
            trigger: 'Component',
          }}
        />
      </div>
    </div>
  );
};

const mapDispatchToProps = { deleteKpi };

const Container = connect(null, mapDispatchToProps)(KpiCard);

export { Container as KpiCard };
