import React from 'react';
import i18n from 'i18next';
import { round, toNum } from '@oliasoft-open-source/units';
import { Text } from '@oliasoft-open-source/react-ui-library';
import translations from '~src/internationalisation/translation-map.json';
import { validateString } from '~src/validation/common/validate-string';
import { activityStatuses } from '~src/enums/tasks';
import { itemFields } from '~src/enums/time-tracker';
import { formatTimeToUI } from './utils/date/dateUtils';
import { CellWithExpandingIcon } from './cell-with-expanding-icon';

const fieldMap = Object.values(itemFields);
const getFields = (disabledFields) =>
  disabledFields.length === 0
    ? fieldMap
    : fieldMap.filter((item) => !disabledFields.includes(item));
const operationVariance = (operation) =>
  round(
    operation.activities.reduce(
      (acc, activity) =>
        acc + (activity.actual ? activity.actual - activity.afe : 0),
      0,
    ),
    2,
  );

const calculateTaskStatus = (task) => {
  switch (true) {
    case new Date() > new Date(task.finish):
      return activityStatuses.FINISHED;
    case new Date() < new Date(task.start):
      return activityStatuses.NEW;
    default:
      return activityStatuses.IN_PROGRESS;
  }
};
export const buildOperation = (item, disabledFields, expandedOperationIds) => {
  const rowStyle = {
    style: { backgroundColor: 'var(--color-background-table-header)' },
  };
  const isExpanded = expandedOperationIds?.includes(item.timeTrackerItemId);

  return getFields(disabledFields).map((fieldName, idx) => {
    if (
      fieldName === 'name' &&
      (item.status === activityStatuses.FINISHED ||
        item.status === activityStatuses.IN_PROGRESS)
    ) {
      return {
        ...rowStyle,
        breakWord: false,
        value: (
          <CellWithExpandingIcon
            cellContent={item[fieldName]}
            index={idx}
            isExpanded={isExpanded}
          />
        ),
      };
    }
    if (fieldName === 'start' || fieldName === 'finish')
      return {
        ...rowStyle,
        value: (
          <CellWithExpandingIcon
            cellContent={formatTimeToUI(item[fieldName])}
            index={idx}
            isExpanded={isExpanded}
          />
        ),
      };
    if (fieldName === itemFields.VARIANCE)
      return {
        ...rowStyle,
        value: operationVariance(item),
      };
    if (fieldName === itemFields.SECTION)
      return {
        ...rowStyle,
        value: item?.section?.name || item.sectionName,
      };
    if (fieldName === itemFields.ACTIVITY)
      return {
        ...rowStyle,
        value: item?.activityName,
      };
    if (fieldName === itemFields.OPERATION)
      return {
        ...rowStyle,
        value: item?.operationName,
      };
    return {
      ...rowStyle,
      value: (
        <CellWithExpandingIcon
          cellContent={item[fieldName]}
          index={idx}
          isExpanded={isExpanded}
        />
      ),
    };
  });
};

export const buildActivity = (
  item,
  actualDepthPropose,
  debounceUpdateTask,
  cellValueUpdated,
  disabledFields,
  projectId,
  disabled = false,
) => {
  const rowStyle = {
    style: { backgroundColor: '' },
  };

  if (calculateTaskStatus(item) === activityStatuses.FINISHED && item?.actual) {
    rowStyle.style.backgroundColor = '#f8fff7';
  }
  return getFields(disabledFields).map((fieldName) => {
    switch (fieldName) {
      case 'afe':
      case 'plannedDepth':
        return {
          ...rowStyle,
          value: <Text muted>{item[fieldName]}</Text>,
          disabled: true,
        };
      case 'activity':
        return {
          ...rowStyle,
          value: <Text muted>{item.activityName}</Text>,
          disabled: true,
        };
      case 'operation':
        return {
          ...rowStyle,
          value: <Text muted>{item?.operationName}</Text>,
          disabled: true,
        };
      case 'section':
        return {
          ...rowStyle,
          value: <Text muted>{item.section || item.sectionName}</Text>,
          disabled: true,
        };
      case 'start':
      case 'finish':
        return {
          ...rowStyle,
          value: (
            <Text muted disabled>
              {formatTimeToUI(item[fieldName])}
            </Text>
          ),
          disabled: true,
        };
      case 'variance': {
        let variance = '';
        if (item.actual !== null) {
          variance = round(item.actual - item.afe, 2);
        }
        return {
          ...rowStyle,
          value: <Text muted>{variance}</Text>,
          disabled: true,
        };
      }
      case 'name': {
        return {
          ...rowStyle,
          value: item[fieldName] || '',
          type: 'Input',
          error: i18n.t(validateString(item[fieldName] || '')),
          onChange: (e) => {
            cellValueUpdated({
              id: item.timeTrackerItemId,
              parentId: item.parentId,
              value: e.target.value,
              field: fieldName,
            });
            if (!validateString(item[fieldName] || '')) {
              debounceUpdateTask.current(item.timeTrackerItemId, {
                [fieldName]: e.target.value,
                projectId,
                field: fieldName,
                parentId: item.parentId,
              });
            }
          },
          disabled,
        };
      }
      default: {
        return {
          ...rowStyle,
          value: item[fieldName] ?? '',
          placeholder: fieldName === 'actualDepth' ? actualDepthPropose : null,
          type: 'NumberInput',
          allowEmpty: true,
          left: true,
          disabled,
          onChange: (e) => {
            const { value } = e.target;

            cellValueUpdated({
              id: item.timeTrackerItemId,
              parentId: item.parentId,
              value,
              field: fieldName,
            });

            debounceUpdateTask.current(item.timeTrackerItemId, {
              [fieldName]: value ? toNum(value) : null,
              projectId,
              field: fieldName,
              parentId: item.parentId,
            });
          },
        };
      }
    }
  });
};

export const buildHeaders = (disabledFields, labelCofig = {}) => {
  const cellsMap = [
    {
      columnName: itemFields.START,
      value: i18n.t(translations.timeTracker_startTime),
    },
    {
      columnName: itemFields.NAME,
      value: `${i18n.t(translations.operation)}/${i18n.t(
        translations.activity,
      )}`,
    },
    {
      columnName: itemFields.AFE,
      value: labelCofig[itemFields.AFE] || i18n.t(translations.timeTracker_afe),
    },
    {
      columnName: itemFields.ESTIMATE,
      value:
        labelCofig[itemFields.ESTIMATE] ||
        i18n.t(translations.timeTracker_estimate),
    },
    {
      columnName: itemFields.ACTUAL,
      value: i18n.t(translations.timeTracker_actual),
    },
    {
      columnName: itemFields.VARIANCE,
      value: 'Variance',
    },
    {
      columnName: itemFields.FINISH,
      value: i18n.t(translations.timeTracker_finishTime),
    },
    {
      columnName: itemFields.PLANNED_DEPTH,
      value: i18n.t(translations.timeTracker_planned),
    },
    {
      columnName: itemFields.ACTUAL_DEPTH,
      value: i18n.t(translations.timeTracker_actualDepth),
    },
    {
      columnName: itemFields.ACTIVITY,
      value: 'Activity',
    },
    {
      columnName: itemFields.OPERATION,
      value: 'Operation',
    },
    {
      columnName: itemFields.SECTION,
      value: 'Section',
    },
  ];

  const cells = cellsMap.filter(
    (item) =>
      !disabledFields.some(
        (disabledField) => disabledField === item.columnName,
      ),
  );

  const depthColSpan = cells.filter((item) =>
    [itemFields.PLANNED_DEPTH, itemFields.ACTUAL_DEPTH].includes(
      item.columnName,
    ),
  ).length;
  const timeColSpan = cells.filter((item) =>
    [
      itemFields.AFE,
      itemFields.ESTIMATE,
      itemFields.ACTUAL,
      itemFields.VARIANCE,
    ].includes(item.columnName),
  ).length;

  const codeColSpan = cells.filter((item) =>
    [itemFields.ACTIVITY, itemFields.OPERATION, itemFields.SECTION].includes(
      item.columnName,
    ),
  ).length;

  const showStart = cells.some((item) => item.columnName === itemFields.START);
  const showFinish = cells.some(
    (item) => item.columnName === itemFields.FINISH,
  );

  return [
    {
      cells,
    },
    {
      cells: [
        ...(showStart
          ? [
              {
                value: '',
              },
            ]
          : []),
        {
          value: '',
        },
        ...(timeColSpan === 0
          ? []
          : [
              {
                value: 'h',
                style: { color: 'hsl(60, 13%, 44%)', fontWeight: 'normal' },
              },
              {
                value: 'h',
                style: { color: 'hsl(60, 13%, 44%)', fontWeight: 'normal' },
              },
              {
                value: 'h',
                style: { color: 'hsl(60, 13%, 44%)', fontWeight: 'normal' },
              },
              {
                value: 'h',
                style: { color: 'hsl(60, 13%, 44%)', fontWeight: 'normal' },
              },
            ]),
        ...(showFinish
          ? [
              {
                value: '',
              },
            ]
          : []),
        ...(depthColSpan === 0
          ? []
          : [
              {
                value: 'm',
                style: { color: 'hsl(60, 13%, 44%)', fontWeight: 'normal' },
              },
              {
                value: 'm',
                style: { color: 'hsl(60, 13%, 44%)', fontWeight: 'normal' },
              },
            ]),
        ...(codeColSpan === 0
          ? []
          : [
              {
                value: '',
                colSpan: codeColSpan,
              },
            ]),
      ],
    },
  ];
};
