import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import i18n from 'i18next';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Field,
  InputGroup,
  Spacer,
  InputGroupAddon,
} from '@oliasoft-open-source/react-ui-library';
import {
  initialKpi,
  updateUserKpi,
} from '~store/entities/user-settings/user-settings';
import { updateCompanyKpi } from '~store/entities/company-settings/company-settings';
import { getResolver } from '~src/validation/resolver';
import translations from '~src/internationalisation/translation-map.json';
import { kpiSchema } from '~schemas/settings/kpi.schema';
import {
  Input,
  Select,
  RadioButton,
  Toggle,
  NumberInput,
} from '~common/form-inputs';
import { useAutoSave } from '~common/auto-save/use-auto-save';
import { autoSaveWait } from '~src/config/config';
import {
  depthInequality,
  valueTypes,
  kpiFractionTypes,
  timeUnits,
  lengthUnits,
  settingEntity,
} from '~src/enums/settings';
import { getSelectOptions } from '~common/lists/lists';
import { selectCurrencies } from '~store/entities/company-settings/selectors';

const KpiForm = ({
  entity,
  settings,
  activeKpi,
  updateUserKpi,
  updateCompanyKpi,
  targetOptions,
  valueTypesOptions,
  kpiFractionOptions,
  unitsMap,
}) => {
  const { t } = useTranslation();
  const { company } = useParams();
  const {
    control,
    handleSubmit,
    watch,
    trigger,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    mode: 'all',
    defaultValues: initialKpi,
    resolver: getResolver(kpiSchema),
  });
  useEffect(() => {
    const formData = { ...activeKpi };
    delete formData.active;
    reset(formData || initialKpi);
  }, [activeKpi, reset]);

  const updateKpi =
    entity === settingEntity.USER ? updateUserKpi : updateCompanyKpi;
  const debounceUpdateKpi = useRef(debounce(updateKpi, autoSaveWait));
  const onSubmit = handleSubmit((data) => {
    debounceUpdateKpi.current(data, settings, company);
  });

  useAutoSave(onSubmit, watch);

  const valueType = watch('valueType');
  const numerator = watch('numerator');
  const denominator = watch('denominator');
  const isTarget = watch('isTarget');
  const numeratorUnit = watch('numeratorUnit');
  const denominatorUnit = watch('denominatorUnit');
  const denominatorOptions = kpiFractionOptions.filter(
    (item) => item.value !== numerator,
  );

  return (
    <form>
      <Field label={t(translations.name)}>
        <Input name="name" control={control} errors={errors} width={300} />
      </Field>
      <Spacer />
      <Field label={t(translations.kpi)}>
        <RadioButton
          name="valueType"
          control={control}
          inline={true}
          options={valueTypesOptions}
          onChange={(e) => {
            if (e.target.value === valueTypes.SINGLE) {
              setValue('denominator', null);
              setValue('denominatorUnit', null);
            } else {
              setValue('denominator', initialKpi.denominator);
              setValue('denominatorUnit', initialKpi.denominatorUnit);
            }
            trigger(['denominator', 'denominatorUnit']);
          }}
        />
      </Field>
      <InputGroup width="500px">
        <Select
          name="numerator"
          control={control}
          errors={errors}
          options={kpiFractionOptions}
          width="160px"
          onChange={(e) => {
            const { value } = e.target;
            setValue('numeratorUnit', unitsMap[value].at(0).value);
            trigger(['numeratorUnit']);

            if (valueType === valueTypes.CALCULATED) {
              const denominatorValue = kpiFractionOptions
                .filter((item) => item.value !== value)
                .at(0).value;
              setValue('denominator', denominatorValue);
              setValue(
                'denominatorUnit',
                unitsMap[denominatorValue].at(0).value,
              );
              trigger(['denominator', 'denominatorUnit']);
            }
          }}
        />
        <Select
          name="numeratorUnit"
          control={control}
          errors={errors}
          options={unitsMap[numerator]}
          width="150px"
        />
        {valueType === valueTypes.CALCULATED && (
          <>
            <InputGroupAddon>per</InputGroupAddon>
            <Select
              name="denominator"
              control={control}
              errors={errors}
              options={denominatorOptions}
              width="160px"
              onChange={(e) => {
                setValue(
                  'denominatorUnit',
                  unitsMap[e.target.value].at(0).value,
                );
                trigger(['denominatorUnit']);
              }}
            />
            <Select
              name="denominatorUnit"
              control={control}
              errors={errors}
              options={unitsMap[denominator]}
              width="150px"
            />
          </>
        )}
      </InputGroup>
      <Spacer />
      <Field label={t(translations.target)}>
        <Toggle
          name="isTarget"
          label={t(translations.settings_addTarget)}
          control={control}
          onChange={(e) => {
            if (e.target.value === 'false') {
              setValue('targetInequality', null);
            } else {
              setValue('targetInequality', initialKpi.targetInequality);
            }
            setValue('target', 0);
            trigger(['targetInequality', 'target']);
          }}
        />
      </Field>
      {isTarget && (
        <InputGroup width="310px">
          <Select
            name="targetInequality"
            control={control}
            errors={errors}
            options={targetOptions}
            width={160}
          />
          <NumberInput name="target" control={control} errors={errors} />
          <InputGroupAddon>
            {numeratorUnit}
            {denominatorUnit ? `/${denominatorUnit}` : null}
          </InputGroupAddon>
        </InputGroup>
      )}
    </form>
  );
};

const mapStateToProps = ({ entities }) => {
  const targetOptions = [
    {
      label: i18n.t(translations.settings_lessOrEqualTo),
      value: depthInequality.LESS_OR_EQUAL,
    },
    {
      label: i18n.t(translations.settings_moreThan),
      value: depthInequality.MORE_THAN,
    },
  ];

  const valueTypesOptions = [
    {
      label: i18n.t(translations.settings_calculatedValue),
      value: valueTypes.CALCULATED,
    },
    {
      label: i18n.t(translations.settings_singleValue),
      value: valueTypes.SINGLE,
    },
  ];

  const kpiFractionOptions = [
    {
      label: i18n.t(translations.costSetup_totalCost),
      value: kpiFractionTypes.COST,
    },
    {
      label: i18n.t(translations.settings_dryHoleDays),
      value: kpiFractionTypes.TIME,
    },
    {
      label: i18n.t(translations.settings_metresDrilled),
      value: kpiFractionTypes.DEPTH,
    },
  ];

  const currencies = selectCurrencies(entities);

  const unitsMap = {
    [kpiFractionTypes.COST]: getSelectOptions(currencies),
    [kpiFractionTypes.TIME]: getSelectOptions(timeUnits),
    [kpiFractionTypes.DEPTH]: getSelectOptions(lengthUnits),
  };

  return {
    targetOptions,
    valueTypesOptions,
    kpiFractionOptions,
    unitsMap,
  };
};

const mapDispatchToProps = { updateUserKpi, updateCompanyKpi };

const Container = connect(mapStateToProps, mapDispatchToProps)(KpiForm);

export { Container as KpiForm };
